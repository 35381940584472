import React from "react"

import CurrentlyReading from "../components/widgets/currentlyReading"
import { Box, SiteLink } from "../helpers/styledComponents"
import Layout from "../components/layout/base"
import SEO from "../components/seo"
import theme from "../helpers/theme"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="home" />
      <Box width="100%" minHeight="80vh" background={theme.colors.lychee}>
        <CurrentlyReading
          background={theme.colors.lychee}
          showProgressBar={false}
        />
        <Box display="flex" justifyContent="center" pt="3" pb={[4, 5]}>
          <SiteLink to="shelf">View book shelf</SiteLink>
        </Box>
      </Box>
    </Layout>
  )
}

export default IndexPage
